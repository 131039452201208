import { CoreMenu } from '@core/types';

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

function isAllowed(moduleName) {
  if (localStorage.getItem('menuPermissions')) {
    if (JSON.parse(localStorage.getItem('menuPermissions')).includes(moduleName)) {
      return false
    } else {
      return true
    }
  } else {
    return true
  }
}

function isAllowedParent(moduleName) {
  if (localStorage.getItem('menuPermissionsParent')) {
    if (JSON.parse(localStorage.getItem('menuPermissionsParent')).includes(moduleName)) {
      return false
    } else {
      return true
    }
  } else {
    return true
  }
}
export const menu = [
  // {
  //   id: 'WORKFLOW',
  //   type: 'section',
  //   title: 'Workflow',
  //   icon: 'package',
  // hidden: isAllowedParent('workflow'),
  //   children: [
  //     {
  //       id: 'workflow',
  //       title: 'Workflow',
  //       type: 'item',
  //       icon: 'message-square',
  //       url: 'apps/master/workflow',
  // hidden: isAllowed('workflow'),
  //     },
  //     // {
  //     //   id: 'assign-workflow',
  //     //   title: 'Assign Workflow',
  //     //   type: 'item',
  //     //   icon: 'message-square',
  //     //   url: 'apps/workflow/assign-workflow',
  //   hidden: isAllowed('assign-workflow'),
  //     // }
  //   ]
  // },
  {
    id: 'MASTERS',
    type: 'section',
    title: 'Masters',
    // translate: 'MENU.APPS.SECTION',
    icon: 'package',
    hidden: isAllowedParent('masters'),
    children: [
      {
        id: 'user',
        title: 'User Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/user',
        hidden: isAllowed('user-master'),
      },
      {
        id: 'roles',
        title: 'Role Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/roles',
        hidden: isAllowed('role-master'),
      },
      {
        id: 'category',
        title: 'Category Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/category',
        hidden: isAllowed('category-master'),
      },
      // {
      //   id: 'escalation',
      //   title: 'Escalation Master',
      //   // translate: 'MENU.APPS.Main Form',
      //   type: 'item',
      //   icon: 'grid',
      //   url: 'apps/master/escalation',
      //   hidden: isAllowed('escalation-master'),
      // },

      {
        id: 'escalation',
        title: 'Escalation Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/escalation',
        hidden: isAllowed('escalation-master'),
      },
      {
        id: 'product-category',
        title: 'Product Category Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/product-category',
        hidden: isAllowed('product-category-master'),
      },
      // {
      //   id: 'distributor',
      //   title: 'JDC/Distributor Master',
      //   // translate: 'MENU.APPS.Main Form',
      //   type: 'item',
      //   icon: 'grid',
      //   url: 'apps/master/distributor',
      //   hidden: isAllowed('distributor-master'),
      // },
      {
        id: 'outlet',
        title: 'Outlet Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/outlet',
        hidden: isAllowed('outlet-master'),
      },
      {
        id: 'static outlet',
        title: 'Static Outlet Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/static-outlet',
        hidden: isAllowed('static-outlet-master'),
      },
      {
        id: 'fix outlet',
        title: 'Fix Outlet Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/fix-outlet',
        hidden: isAllowed('fix-outlet-master'),
      },
      {
        id: 'skip outlet',
        title: 'Skipped Outlet Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/skip-outlet',
        hidden: isAllowed('skip-outlet-master'),
      },
      {
        id: 'resolution',
        title: 'Product Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/resolution',
        hidden: isAllowed('resolution-master'),
      },
      {
        id: 'trigger mapping',
        title: 'Trigger Mapping Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/trigger-mapping',
        hidden: isAllowed('trigger-mapping-master'),
      },

    ]
  },
  {
    id: 'GRIEVANCE',
    type: 'section',
    title: 'Grievance',
    icon: 'package',
    hidden: isAllowedParent('transactions'),
    children: [
      // {
      //   id: 'initiator-form',
      //   title: 'Initiator Form',
      //   type: 'item',
      //   icon: 'message-square',
      //   url: 'apps/transactions/initiator-form',
      //   // hidden: isAllowed('initiator form'),
      // },
      {
        id: 'my-requests-closed',
        title: 'Closed Requests',
        type: 'item',
        icon: 'grid',
        url: 'apps/transactions/my-requests-close',
        hidden: isAllowed('my-requests-closed'),
      },
      {
        id: 'my-requests-open',
        title: 'Open Requests',
        type: 'item',
        icon: 'grid',
        url: 'apps/transactions/my-requests',
        hidden: isAllowed('my-requests-closed'),
      },
      {
        id: 'global-user-login',
        title: 'Global User Login',
        type: 'item',
        icon: 'grid',
        url: 'apps/transactions/global-user-login',
        hidden: isAllowed('global-login'),
      }
    ]
  },
  {
    id: 'REPORTS',
    type: 'section',
    title: 'Reports',
    icon: 'package',
    hidden: isAllowedParent('reports'),
    children: [
      // {
      //   id: 'trans-details-report',
      //   title: 'CER Task Report',
      //   type: 'item',
      //   icon: 'message-square',
      //   url: 'apps/reports/trans-report',
      //   // hidden: isAllowed('trans details report'),
      // },
      // {
      //   id: 'cer-format-report',
      //   title: 'Download CER Format',
      //   type: 'item',
      //   icon: 'message-square',
      //   url: 'apps/reports/cer-report',
      //   // hidden: isAllowed('cer format report'),
      // },
      // {
      //   id: 'aging-report',
      //   title: 'CER Aging Report',
      //   type: 'item',
      //   icon: 'message-square',
      //   url: 'apps/reports/aging-report',
      //   // hidden: isAllowed('aging report'),
      // },
      {
        id: 'jersey-report',
        title: 'Grievance Report',
        type: 'item',
        icon: 'grid',
        url: 'apps/reports',
        hidden: isAllowed('report'),
      }
    ]
  },
  {
    id: 'INDENT DETAILS',
    type: 'section',
    title: 'Indent Details',
    icon: 'package',
    hidden: isAllowedParent('indent'),
    children: [
      // {
      //   id: 'trans-details-report',
      //   title: 'CER Task Report',
      //   type: 'item',
      //   icon: 'message-square',
      //   url: 'apps/reports/trans-report',
      //   // hidden: isAllowed('trans details report'),
      // },
      // {
      //   id: 'cer-format-report',
      //   title: 'Download CER Format',
      //   type: 'item',
      //   icon: 'message-square',
      //   url: 'apps/reports/cer-report',
      //   // hidden: isAllowed('cer format report'),
      // },
      // {
      //   id: 'aging-report',
      //   title: 'CER Aging Report',
      //   type: 'item',
      //   icon: 'message-square',
      //   url: 'apps/reports/aging-report',
      //   // hidden: isAllowed('aging report'),
      // },
      {
        id: 'indent-details',
        title: 'Indent Details',
        type: 'item',
        icon: 'grid',
        url: 'apps/indent-details',
        hidden: isAllowed('indent-details'),
      }
    ]
  },
  {
    id: 'MANUAL DETAILS',
    type: 'section',
    title: 'Manual Ticket Details',
    icon: 'package',
    hidden: isAllowedParent('manual'),
    children: [
      // {
      //   id: 'trans-details-report',
      //   title: 'CER Task Report',
      //   type: 'item',
      //   icon: 'message-square',
      //   url: 'apps/reports/trans-report',
      //   // hidden: isAllowed('trans details report'),
      // },
      // {
      //   id: 'cer-format-report',
      //   title: 'Download CER Format',
      //   type: 'item',
      //   icon: 'message-square',
      //   url: 'apps/reports/cer-report',
      //   // hidden: isAllowed('cer format report'),
      // },
      // {
      //   id: 'aging-report',
      //   title: 'CER Aging Report',
      //   type: 'item',
      //   icon: 'message-square',
      //   url: 'apps/reports/aging-report',
      //   // hidden: isAllowed('aging report'),
      // },
      {
        id: 'manual-ticket-details',
        title: 'Manual Ticket Details',
        type: 'item',
        icon: 'grid',
        url: 'apps/indent-details/manual-ticket-details',
        hidden: isAllowed('ticket-creation-details'),
      }
    ]
  },
];


